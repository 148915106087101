const footerLinksSpanish = [
    {
      // Personal Banking,
      title: "Banca Personal",
      id: "sml-personal-banking",
      url: "/es",
      links: [
        {
          // Checking
          id: "sml-pb-checking-account",
          show: true,
          text: "Cuentas de Cheques",
          url: "/es/banca-personal/cuenta-de-cheques"
        },
        {
          // Free Checking
          id: "sml-pb-free-checking-account",
          show: true,
          text: "Cuenta de Cheques Gratuita",
          url: "/es/banca-personal/gratis-cuenta-de-cheques"
        },
        {
          // Rewards and Premium Rewards Checking
          id: "mml-pb-rewards-and-premium-rewards-checking",
          show: true,
          text: "Rewards y Premium Rewards Checking",
          url: "/es/banca-personal/rewards-checking"
        },
        {
          // Interest Checking
          id: "mml-pb-interest-checking",
          show: true,
          text: "Interest Checking",
          url: "/es/banca-personal/interest-rewards-checking"
        },
        {
          // Fresh Start
          id: "sml-pb-fresh-start",
          show: true,
          text: "Cuenta Nuevo Comienzo (Fresh Start)",
          url: "/es/banca-personal/cuenta-nuevo-comienzo"
        },
        {
          // Savings, CDs & Money Markets
          id: "sml-pb-savings-cd-mm",
          show: true,
          text: "Cuentas de Ahorro, Cuentas a Plazo Fijo (CD) y Cuentas de Money Market",
          url: "/es/banca-personal/cuentas-de-ahorro"
        },
        {
          // Retirement Savings (IRA)
          id: "sml-pb-ira-accounts",
          show: false,
          text: "Cuentas Individuales de Retiro o Jubilación (IRA)",
          url: "/es/banca-personal/jubilacion-ira"
        },
        {
          // Credit Cards
          id: "sml-pb-credit-cards",
          show: true,
          text: "Tarjetas de Crédito",
          url: "/es/banca-personal/tarjetas-de-credito"
        },
        {
          // Fees
          id: "sml-pb-account-fees",
          show: true,
          text: "Cargos",
          url: "/es/banca-personal/cargos"
        },
        {
          // Disclosures
          id: "sml-pb-account-details",
          show: true,
          text: "Detalles de la Cuenta",
          url: "/es/detalles-de-la-cuenta"
        },
        {
          // Personal Account Sign In
          id: "sml-pb-personal-account-login",
          show: true,
          text: "Iniciar Sesión en una Cuenta Personal",
          url: "/es/banca-personal/entrar"
        }
      ]
    },
    {
      // Home Loans
      title: "Préstamos hipotecarios",
      id: "sml-home-loans",
      url: "/es/banca-personal/prestamos-hipotecarios",
      links: [
        {
          // Apply for a Loan - Contact loan officer
          id: "sml-hl-apply-online",
          show: true,
          text: "Contacte a un asesor hipotecario",
          url: "/es/banca-personal/contacte-a-un-asesor-hipotecario"
        },
        {
          // Land Loans
          id: "sml-hl-land-loans",
          show: true,
          text: "Préstamos para terrenos",
          url: "/es/banca-personal/prestamos-hipotecarios/prestamos-para-lotes-y-terrenos"
        },
        {
          // Smart Start
          id: "sml-hl-smart-start",
          show: true,
          text: "Hipoteca Smart Start",
          url: "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start"
        },
        {
          // Homeowner Assistance
          id: "sml-hl-homeowner-assistance",
          show: true,
          text: "Asistencia al propietario",
          url: "/es/banca-personal/prestamos-hipotecarios/asistencia-a-propietarios"
        }
      ]
    }
  ],
  socialLinksSpanish = [
    {
      // Facebook
      id: "fl-facebook-link",
      show: true,
      url: "https://www.facebook.com/wafdbank",
      icon: {
        lib: "fab",
        title: "Facebook",
        name: "facebook-f",
        class: "text-white fa-lg"
      }
    },
    {
      // Twitter
      id: "fl-twitter-link",
      show: true,
      url: "https://www.twitter.com/wafdbank",
      icon: {
        lib: "fab",
        title: "X (Twitter)",
        name: "xtwitter",
        class: "text-white fa-lg"
      }
    },
    {
      // LinkedIn
      id: "fl-linked-in-link",
      show: true,
      url: "https://www.linkedin.com/company/wafd-bank/",
      icon: {
        lib: "fab",
        title: "LinkedIn",
        name: "linkedin-in",
        class: "text-white fa-lg"
      }
    },
    {
      // YouTube
      id: "fl-youtube-link",
      show: true,
      url: "https://www.youtube.com/@WAFDBank?sub_confirmation=1",
      icon: {
        lib: "fab",
        title: "YouTube",
        name: "youtube",
        class: "text-white fa-lg"
      }
    },
    {
      // InstaGram
      id: "fl-instagram-link",
      show: true,
      url: "https://www.instagram.com/wafdbank/",
      icon: {
        lib: "fab",
        title: "Instagram",
        name: "instagram",
        class: "text-white fa-lg"
      }
    }
  ],
  aboutUsLinksSpanish = [
    {
      // About Us
      id: "mm-ab-about",
      show: false,
      class: "",
      textMobile: "About Us",
      text: "About WaFd Bank",
      url: "/about-us"
    },
    {
      // FAQs
      id: "mm-ab-faqs",
      show: true,
      class: "ml-3",
      text: "Preguntas Frecuentes",
      url: "/es/preguntas-frecuentes"
    },
    {
      // Contact Us
      id: "mm-ab-contact-us",
      show: true,
      class: "ml-3",
      text: "Contáctanos",
      url: "/es/contactanos"
    },
    {
      // Terms of Use
      id: "bfl-terms-of-use",
      show: true,
      class: "ml-3",
      text: "Términos de Uso",
      url: "/terms-of-use"
    },
    {
      // Privacy Center
      id: "bfl-privacy-security",
      show: true,
      class: "ml-3",
      text: "Privacidad y Seguridad",
      url: "/privacy-center"
    }
  ];

module.exports = {
  footerLinksSpanish,
  socialLinksSpanish,
  aboutUsLinksSpanish
};
