import React from "react";
import { Link } from "gatsby";

// Icons
import Icon from "../custom-widgets/icon";
import CookieControlIcon from "../../assets/cookie-control-icon.svg";
import EqualHousingLogo from "../../images/equal-housing-lender-logo.svg";

// Styles
import styles from "./footer.module.scss";

const MobileFooter = (props) => {
  function scrollToTop() {
    if (window !== "undefined") {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

  const AddressInfo = () => (
    <div className="container">
      <div className="d-flex mb-3" id="footer-mobile-c3-tel">
        <p className="mb-0 text-sm">
          <a href="tel:800-324-9375" id="footer-mobile-c3-tel-link">
            <Icon lib="fal" name="phone-alt" fixedWidth="2rem" class="mr-2 mt-1 fa-lg" />
            800-324-9375
          </a>
        </p>
      </div>
      <div className="mb-3" id="footer-mobile-wafd-address">
        <a
          className="text-sm d-flex"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.com/maps/place/WaFd+Bank+-+Corporate+Office/@47.610432,-122.3382189,17z/data=!3m1!4b1!4m6!3m5!1s0x54906b3f29e8d447:0xf79dee77d94c038b!8m2!3d47.610432!4d-122.335644!16s%2fg%2f11tn2ndr4p?entry=ttu&g_ep=EgoyMDI0MTAyMi4wIKXMDSoASAFQAw%3D%3D"
          id="footer-mobile-wafd-address-link"
        >
          <Icon lib="fal" name="map-marker-alt" fixedWidth="2rem" class="mr-2 mt-1 fa-lg" />
          425 Pike Street
          <br />
          Seattle, WA 98101
        </a>
      </div>
      <div className="d-flex mb-3" id="footer-mobile-wafd-routing-number">
        <Icon lib="fal" name="arrows-alt" fixedWidth="2rem" class="mr-2 fa-lg" />
        <p className="mb-0 text-sm">Routing # 325070980</p>
      </div>
    </div>
  );

  return (
    <>
      {props.isSpanish ? (
        <div id="footer-mobile-spanish" className="d-block d-md-none pb-4 pt-3 border-top">
          <AddressInfo />
          {/* BACK TO TOP */}
          <div className="text-center text-center py-1 mb-5 bg-light">
            <Icon lib="fas" name="sort-up" class="text-secondary d-block mx-auto fa-lg" />
            <a
              href="#main-content"
              onClick={(e) => {
                e.preventDefault();
                scrollToTop();
              }}
            >
              Volver arriba
            </a>
          </div>

          {/* SOCIAL ICONS */}
          <p className="d-flex justify-content-center">
            {props.socialLinks.map((item) => (
              <a key={item.id} id={item.id} href={item.url} rel="noopener noreferrer" target="_blank">
                <span className={`mx-2 ${styles.iconWrapper}`}>
                  {item.icon.name === "xtwitter" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="text-white"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                      title="X (Twitter)"
                    >
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                    </svg>
                  ) : (
                    <Icon {...item.icon} />
                  )}
                </span>
              </a>
            ))}
          </p>

          {/* LINKS */}
          <div className="container mb-3">
            <div className="row">
              <div className="col-6 border-right">
                <small>
                  <div className="mb-3">
                    <Icon name="lock" class="mr-2 fa-lg" />
                    Iniciar sesión
                  </div>
                  <Link className="d-block" to="/es/banca-personal/entrar">
                    Banca personal
                  </Link>
                </small>
              </div>
              <div className="col-6">
                <small>
                  <div className="mb-3">Enlaces</div>
                  <Link className="d-block" to="/terms-of-use">
                    Términos de uso
                  </Link>
                  <Link className="d-block" to="/privacy-center">
                    Privacidad y seguridad
                  </Link>
                </small>
              </div>
            </div>
          </div>

          {/* LEGAL */}
          <small className="text-center">
            <p className="text-muted mx-1">
              WaFd Bank es un DBA (Doing Business As) del Washington Federal Bank, Asociación Nacional. Miembro de FDIC.{" "}
              <span className="d-inline-block">
                Prestamista de vivienda igualitaria{" "}
                <img
                  src={EqualHousingLogo}
                  className="mb-1"
                  alt="Equal Housing Lender logo"
                  style={{ width: "22px", height: "16px" }}
                />
              </span>
            </p>
            <p className="text-muted mx-1">
              &copy; {new Date().getFullYear()}, WaFd Bank, Todos los derechos reservados. Compañía NMLSR #410394
            </p>
          </small>
        </div>
      ) : (
        <div id="footer-mobile" className="d-block d-md-none pb-4 pt-3 border-top">
          <AddressInfo />
          {/* BACK TO TOP */}
          <div className="text-center text-center py-1 mb-4 bg-light">
            <Icon lib="fas" name="sort-up" class="text-secondary d-block mx-auto fa-lg" />
            <a
              href="#main-content"
              onClick={(e) => {
                e.preventDefault();
                scrollToTop();
              }}
            >
              Back to Top
            </a>
          </div>
          {/* SOCIAL ICONS */}
          <p className="d-flex justify-content-center mb-4">
            {props.socialLinks.map((item) => (
              <a key={item.id} id={`${item.id}-m`} href={item.url} rel="noopener noreferrer" target="_blank">
                <span className={`mx-2 ${styles.iconWrapper}`}>
                  {item.icon.name === "xtwitter" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="text-white"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                      title="X (Twitter)"
                    >
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                    </svg>
                  ) : (
                    <Icon {...item.icon} />
                  )}
                </span>
              </a>
            ))}
          </p>

          {/* LINKS */}
          <ul className="container mb-4 list-unstyled">
            <li className="mb-3">
              <Link
                id="footer-personal-banking-link"
                className="d-block text-decoration-none"
                to="/personal-banking/login"
              >
                <Icon name="user" class="mr-2" />
                Sign in to Personal Banking
              </Link>
            </li>
            <li className="mb-3">
              <Link
                id="footer-treasury-express-link"
                className="d-block text-decoration-none"
                to="https://commercial.washingtonfederal.com/smallbusiness/auth"
              >
                <Icon name="university" class="mr-2" />
                Sign in to WAFD Treasury Express
              </Link>
            </li>
            <li className="mb-3">
              <Link
                id="footer-treasury-prime-link"
                className="d-block text-decoration-none"
                to="https://commercial.washingtonfederal.com/"
              >
                <Icon name="university" class="mr-2" />
                Sign in to WAFD Treasury Prime
              </Link>
            </li>
            <li className="mb-3">
              <Link id="footer-accessibility-link" className="d-block text-decoration-none" to="/accessibility">
                <Icon name="universal-access" class="mr-2" />
                Accessibility
              </Link>
            </li>
            <li className="mb-3">
              <Link
                id="footer-ccpa-notice-link"
                className="d-block text-decoration-none"
                to="/privacy-center#ccpa-notice"
              >
                <Icon name="server" class="mr-2 fa-w-16" />
                Notice of Data Collection
              </Link>
            </li>
            <li className="mb-3">
              <Link id="footer-terms-of-use-link" className="d-block text-decoration-none" to="/terms-of-use">
                <Icon name="file-alt" class="mr-2 fa-w-16" />
                Terms of Use
              </Link>
            </li>
            <li className="mb-3">
              <Link
                id="footer-online-and-mobile-banking-service-agreement-link"
                className="d-block text-decoration-none"
                to="/online-and-mobile-banking-service-agreement"
              >
                <Icon name="file-alt" class="mr-2 fa-w-16" />
                Online Banking Service Agreement
              </Link>
            </li>
            <li className="mb-3">
              <Link id="footer-privacy-and-security-link" className="d-block text-decoration-none" to="/privacy-center">
                <Icon name="shield-check" class="mr-2" />
                Privacy Center
              </Link>
            </li>
            <li className="mb-3">
              <Link
                id="footer-cookie-control-link"
                className="d-block text-decoration-none"
                to="/privacy-center#cookie-control"
              >
                <Icon name="cookie-bite" class="mr-2" />
                Cookie Control
                <img src={CookieControlIcon} className="ml-1" alt="" style={{ width: "31px", height: "14px" }} />
              </Link>
            </li>
          </ul>

          {/* LEGAL */}
          <div className="container">
            <p className="text-muted mb-4">
              WaFd Bank is a DBA (Doing Business As) of Washington Federal Bank. Member FDIC.{" "}
              <span className="d-inline-block">
                Equal Housing Lender{" "}
                <img
                  src={EqualHousingLogo}
                  className="mb-1"
                  alt="Equal Housing Lender logo"
                  style={{ width: "22px", height: "16px" }}
                />
              </span>
            </p>
            <p className="text-muted mb-4">
              &copy; {new Date().getFullYear()}, WaFd Bank, All Rights Reserved. NMLSR Company #410394
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileFooter;
